import { RECALL_CLASSIFICATIONS, COMMUNICATION_TYPE, RECALL_STATUS,OUTPUT_DATE_FORMAT,PRODUCT_DISRUPTION_TYPE } from '../../../../../misc/commons'
import DocumentViewModel from '../../../../../components/part/shared/DocumentViewModel'
import ActionType from '../../../../../components/part/provider/ActionType'
import _ from 'lodash'
export default {
    props: {
        organizationId: {type: Number, default: null},
        communicationId: {type: String, default: null},
        providerCommunicationId: {type: Number, default: null},
        communicationType: { type: String, default: COMMUNICATION_TYPE.RECALL },
        item: {type: Object, default: null},
        internalActions: {type: Array, default: null},
        histories: {type: Array, default: null},
        headerName: {type: String, default: null}
    },
    components: {
        DocumentViewModel,
        ActionType,
    },
    data() {
        return {
            recall_classifications: RECALL_CLASSIFICATIONS,
            recall_status: RECALL_STATUS,
            disruption_type: PRODUCT_DISRUPTION_TYPE,
            recall: COMMUNICATION_TYPE.RECALL,
            disruption: COMMUNICATION_TYPE.PRODUCTDISRUPTION,
            OUTPUT_DATE_FORMAT: OUTPUT_DATE_FORMAT,
            isOlderExpanded: false,
            cnt: 1,
            isExpanded: true,
            labels: {
                webformHeader: 'Recall Information',
                riskToHealth: 'Risk To Health',
                name: 'Recall Information',
                communicationUID: 'Recall ID',
                type: 'Recall Type',
                status: 'Recall Status',
                communicationDate: 'Recall Date',
                categories: 'Recall Category',
                supportingMaterials: 'Supporting Materials',
                products: 'Recall Products',
            },
            moreLess: 'See Less Information',
        }
    },
    created() {
        this.labels={
            webformHeader: this.communicationType== this.recall? 'Recall Information':'Disruption Notice :',
            riskToHealth: this.communicationType== this.recall? 'Risk To Health':'Alternatives & Conservation Measures',
            name: this.communicationType== this.recall? 'Recall Information':'Disruption Notice :',
            communicationUID: this.communicationType== this.recall? 'Recall ID':'Product Disruption ID',
            type: this.communicationType== this.recall? 'Recall Type':'Type',
            status: this.communicationType== this.recall? 'Recall Status':'Status',
            communicationDate: this.communicationType== this.recall? 'Recall Date':'Disruption Date',
            categories: this.communicationType== this.recall? 'Recall Category':'Category',
            supportingMaterials: this.communicationType== this.recall? 'Supporting Materials':'Attachments',
            products: this.communicationType== this.recall? 'Recall Products':'Affected Products',
        }
    },
    methods: {
        expandMoreLess() {
            if (this.isExpanded == false) this.moreLess = 'See Less Information'
            else this.moreLess = 'See More Information'
        },
        ChangeIdentifires(fields, field, event) {
            let rownumber = field.options.filter(x => x.value == event)[0].rowNumber
            let uniquefields = fields.filter(x => x.is_unique_identifier == true && x.field_name != field.field_name)
            for (let index = 0; index < uniquefields.length; index++) {
                let uniquefield = fields.filter(x => x.is_unique_identifier == true && x.field_name == uniquefields[index].field_name)[0]
                uniquefield.selected_value = uniquefield.options.filter(x => x.rowNumber == rownumber)[0].value
            }
        },
    },
}
